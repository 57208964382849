import ModalFormAccount from 'components/Modals/ModalFormAccount';
import { format, addDays } from 'date-fns';
import React from 'react';
import { Table } from 'reactstrap';

function AccountTable(props) {
  const currentDate = new Date();
  const calculateDaysBetweenDates = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    return diffDays;
  };

  // const calculateDeadline = (startDate) => {
  //   const deadline = addDays(startDate, 90);
  //   return deadline;
  // };

  const items = props.items.map((item) => {
    const formattedDate = format(new Date(item.time * 1000), 'dd-MM-yy');
    // const formattedDeadline = format(calculateDeadline(new Date(item.time * 1000)), 'MM-dd-yy');
    // const daysLeft = calculateDaysBetweenDates(new Date(), calculateDeadline(new Date(item.time * 1000)));

    return (
      <tr key={item.id}>
        <th scope="row">{item.id}</th>
        <td>{item.name}</td>
        <td>{item.max_employees}</td>
        <td>{formattedDate}</td>
        {/* <td>{formattedDeadline}</td>
        <td>{daysLeft}</td> */}
        <td>
          <div style={{ width: '110px' }}>
            <ModalFormAccount url={props.url} buttonLabel="Edit" item={item} updateState={props.updateState} />
          </div>
        </td>
      </tr>
    );
  });

  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Names</th>
          <th>Employee limit</th>
          <th>Start date</th>
          {/* <th>Deadline</th>
          <th>Days left</th> */}
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </Table>
  );
}

export default AccountTable;
