import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setToken } from "store/tokenReducer";

function Callback() {
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently,user } = useAuth0();
  const dispatch = useDispatch();
  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        dispatch(setToken(token));
      }
    };

    getToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    <div>
      {isAuthenticated ? (
        // navigate(-2)
        <div ></div>
      ) : (
        <button type="submit" onClick={loginWithRedirect()}>Iniciar sesión</button>
      )}
    </div>
  );
}
  export default Callback