import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

function SignIn() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect(); // Iniciar sesión con redirección cuando el componente se monta
  }, []);

  return null; // Puedes devolver null o cualquier otro contenido que desees mostrar en el componente
}

export default SignIn;