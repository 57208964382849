import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { persistor } from 'store/combineReducers';
import { store } from 'store/combineReducers';

function fetchApi(url, options) {
  const token = store.getState().token.token; // Obtener el token del estado de la aplicación
  // Agregar el token al encabezado de las solicitudes
  options = options || {};
  options.headers = options.headers || {};
  options.headers = {
    ...options.headers,
    'Authorization': 'Bearer ' + token
  };
  return fetch(process.env.REACT_APP_API + '/' + url, options)
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error('Error:', error);
      throw error;
    });
}

export default fetchApi;