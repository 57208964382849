/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { MaterialUIControllerProvider } from "context";
import "bootstrap/dist/css/bootstrap.min.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { persistor, store } from "store/combineReducers";
import { unregister } from "./serviceWorker";
import "./index.css";

const domain = process.env.REACT_APP_DOMAIN;
const baseurl = process.env.REACT_APP_BASEURL;
const audience = process.env.REACT_APP_AUDIENCE
const clientid = process.env.REACT_APP_CLIENTIDAUTH;
function AuthenticatedApp() {
  
  const { isAuthenticated } = useAuth0();
}
ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientid}
    authorizationParams={{
      redirect_uri: baseurl+'/callback',
      scope: "openid profile email",
      audience: audience+"/api/v2/",
    }}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </BrowserRouter>
      </PersistGate>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
