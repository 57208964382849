
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from "react";

function SignUp() {
  const { logout,loginWithRedirect } = useAuth0();

  useEffect(() => {
    logout(); 
  }, []);
  return null;
};

export default SignUp;
