import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ModalForm from '../../components/Modals/Modal';
import DataTable from '../../components/Tables/DataTable';
import { CSVLink } from 'react-csv';
import fetchApi from 'components/Api/Actions';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Tables(props) {
    const [items, setItems] = useState([]);
  
    useEffect(() => {
      getItems();
    }, []);
  
    const getItems = async () => {
      const items = await fetchApi(props.url);
      setItems(items)
    };
  
    const addItemToState = (item) => {
      setItems((prevItems) => [...prevItems, item]);
    };
  
    const updateState = (item) => {
      setItems((prevItems) => {
        const itemIndex = prevItems.findIndex((data) => data.id === item.id);
        const newArray = [
          ...prevItems.slice(0, itemIndex),
          item,
          ...prevItems.slice(itemIndex + 1),
        ];
        return newArray;
      });
    };
  
    const deleteItemFromState = (id) => {
      const updatedItems = items.filter((item) => item.id !== id);
      setItems(updatedItems);
    };
  
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="App">
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Users
                </MDTypography>
              </MDBox>
              <MDBox 
                pt={3} 
                pb={2}
                px={3}>
                  <Col>
                    <DataTable
                      url={props.url}
                      items={items}
                      updateState={updateState}
                      deleteItemFromState={deleteItemFromState}
                    />
                  </Col>
              </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    );
  }

  export default Tables;