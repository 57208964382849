import { Button, Form, FormGroup, Label, Input, Container } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import fetchApi from 'components/Api/Actions';

const AddEditFormAccount = ({ item, addItemToState, updateState, toggle, url }) => {
  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    user: '',
    max_employees: ''
  });

  useEffect(() => {
    if (item) {
      const { id, name, user, max_employees } = item;
      setFormData({ id, name, user, max_employees });
    }
  }, [item]);

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const submitFormAdd = async e => {
    e.preventDefault();
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    };
    const item = await fetchApi(url,options);
    if(item) {
      addItemToState(item)
      toggle()
    } else {
      console.log('failure1')
    }
  };

  const submitFormEdit = async e => {
    e.preventDefault();
    const options = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    };
    const item = await fetchApi(url,options);
    if(item) {
      updateState(item)
      toggle()
    } else {
      console.log('failure1')
    }
  };

  return (
    <Form onSubmit={item ? submitFormEdit : submitFormAdd}>
      <FormGroup className='d-none'>
        <Label for="name">User names</Label>
        <Input
        disabled
          type="text"
          name="name"
          id="name"
          onChange={onChange}
          value={formData.name === null ? '' : formData.name}
        />
      </FormGroup>
      <FormGroup>
        <Label for="max_employees">Employee limit</Label>
        <Input
          type="number"
          name="max_employees"
          id="max_employees"
          onChange={onChange}
          value={formData.max_employees === null ? '' : formData.max_employees}
          placeholder="ex. 100"
        />
      </FormGroup>
      <FormGroup className='d-none'>
        <Label for="start_date">Start Date</Label>
        <Input
        disabled
          type="date"
          name="start_date"
          id="start_date"
          onChange={onChange}
          value={formData.start_date === null ? '' : formData.start_date}
        />
      </FormGroup>
      <Container className='d-flex justify-content-end p-0'>
          <Button>Submit</Button>
        </Container>
    </Form>
  );
};

export default AddEditFormAccount;