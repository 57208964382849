import { Button, Form, FormGroup, Label, Input, Container } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import fetchApi from 'components/Api/Actions';

const AddEditForm = ({ item, addItemToState, updateState, toggle, url }) => {
  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    user: '',
    max_accounts: ''
  });

  useEffect(() => {
    if (item) {
      const { id, name, user, max_accounts } = item;
      setFormData({ id, name, user, max_accounts });
    }
  }, [item]);

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const submitFormAdd = async e => {
    e.preventDefault();
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    };
    const item = await fetchApi(url,options);
    if(item) {
      addItemToState(item)
      toggle()
    } else {
      console.log('failure')
    }
  };

  const submitFormEdit = async e => {
    e.preventDefault();
    const options = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    };
    const item = await fetchApi(url,options);
    if (item) {
      updateState(item);
      toggle();
    } else {
      console.log(item);
    }  
  };

  return (
    <Form onSubmit={item ? submitFormEdit : submitFormAdd}>
      <FormGroup className='d-none'>
        <Label for="name">User name</Label>
        <Input
        disabled
          type="text"
          name="name"
          id="name"
          onChange={onChange}
          value={formData.name === null ? '' : formData.name}
        />
      </FormGroup>
      <FormGroup className='d-none'>
        <Label for="user">Email</Label>
        <Input
        disabled
          type="user"
          name="user"
          id="user"
          onChange={onChange}
          value={formData.user === null ? '' : formData.user}
        />
      </FormGroup>
      <FormGroup>
        <Label for="max_accounts">Campaign limit</Label>
        <Input
          type="number"
          name="max_accounts"
          id="max_accounts"
          onChange={onChange}
          value={formData.max_accounts === null ? '' : formData.max_accounts}
          placeholder="ex. 100"
        />
      </FormGroup>
        <Container className='d-flex justify-content-end p-0'>
          <Button>Submit</Button>
        </Container>
    </Form>
  );
};

export default AddEditForm;