import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { CSVLink } from 'react-csv';
import fetchApi from 'components/Api/Actions';
import AccountTable from 'components/Tables/AccountTable';
import ModalFormAccount from 'components/Modals/ModalFormAccount';
import { Link, useParams } from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Account(props) {
    const { id } = useParams();
    const [items, setItems] = useState([]);

    
    const getItems = async () => {
      const items = await fetchApi(props.url + '/' + id);
      setItems(items)
    };

    useEffect(() => {
        getItems();
      }, []);
  
    const addItemToState = (item) => {
      setItems((prevItems) => [...prevItems, item]);
    };
  
    const updateState = (item) => {
      setItems((prevItems) => {
        const itemIndex = prevItems.findIndex((data) => data.id === item.id);
        const newArray = [
          ...prevItems.slice(0, itemIndex),
          item,
          ...prevItems.slice(itemIndex + 1),
        ];
        return newArray;
      });
    };
  
    const deleteItemFromState = (id) => {
      const updatedItems = items.filter((item) => item.id !== id);
      setItems(updatedItems);
    };
  
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
              <Card className="App">
                <MDBox
                    className="d-flex justify-content-between"
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h4" color="white">
                        Campaigns
                    </MDTypography>
                    <Link to="/users" rel="noreferrer">
                      <MDButton variant="gradient" color="dark">
                        <Icon sx={{ fontWeight: "bold" }}>person</Icon>
                        &nbsp;Users
                      </MDButton>
                    </Link>
                  </MDBox>
                  <MDBox 
                    pt={3} 
                    pb={2}
                    px={3}>
                        <Col>
                        <AccountTable
                          url={props.url}
                          items={items}
                          updateState={updateState}
                          deleteItemFromState={deleteItemFromState}
                        />
                      </Col>
                  </MDBox>
                </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    );
  }

  export default Account;