import ModalForm from 'components/Modals/Modal';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'reactstrap';

function DataTable(props) {
  const items = props.items.map((item) => {
    return (
      <tr key={item.id}>
        <th scope="row">{item.id}</th>
        <td>{item.name}</td>
        <td>{item.user}</td>
        <td>{item.max_accounts}</td>
        <td>
          <div style={{ width: '110px' }}>
            <ModalForm url={props.url} buttonLabel="Edit" item={item} updateState={props.updateState} />
            {' '}
            <Link to={`/account/${item.id}`}  >
              <Button color="primary">
                See more
              </Button>
            </Link>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Campaign limit</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </Table>
  );
}

export default DataTable;