import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import tokenReducer from './tokenReducer';

const rootReducer = combineReducers({
    token: tokenReducer,
});
const persistConfig={
    key:'root',
    storage
}
const persistedReducer = persistReducer(persistConfig,rootReducer);
export const store = configureStore({
    reducer: persistedReducer,
})

export const persistor = persistStore(store);
