import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import AddEditForm from '../Forms/FormAddEdit';

function ModalForm(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal((prevState) => !prevState);
  };

  const closeBtn = (
    <button  type="button" className="btn-close" aria-label="Close" onClick={toggle}>
    </button>
  );

  const label = props.buttonLabel;

  let button = '';
  let title = '';

  if (label === 'Edit') {
    button = (
      <Button color="secondary" onClick={toggle} style={{ float: 'left', marginRight: '10px' }}>
        {label}
      </Button>
    );
    title = 'Edit Item';
  } else {
    button = (
      <Button color="success" onClick={toggle} style={{ float: 'left', marginRight: '10px' }}>
        {label}
      </Button>
    );
    title = 'Add New Item';
  }

  return (
    <div>
      {button}
      <Modal isOpen={modal} toggle={toggle} className={props.className}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {title}
        </ModalHeader>
        <ModalBody>
          <AddEditForm url={props.url}
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            toggle={toggle}
            item={props.item}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}


export default ModalForm;